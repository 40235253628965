import { Switch } from '@headlessui/react';

const StyledSwitch = ({
	checked,
	onChange,
}: {
	checked: boolean;
	onChange: (checked: boolean) => void;
}) => (
	<Switch
		checked={checked}
		onChange={onChange}
		className={`${
			checked
				? 'bg-primary-700 dark:bg-primary-700'
				: 'bg-slate-700 dark:bg-slate-700'
		} relative inline-flex h-6 w-14 shrink-0 origin-right select-none items-center rounded-full transition-all focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2`}
	>
		<span
			className={`${
				checked ? 'translate-x-9' : 'translate-x-1'
			} inline-block size-4 rounded-full bg-white transition-transform`}
		/>
		<span
			aria-hidden
			className={`${
				checked ? '-translate-x-2' : 'translate-x-2.5'
			} font-sans text-xs font-bold text-white transition-transform`}
		>
			{checked ? 'AAN' : 'UIT'}
		</span>
	</Switch>
);

export default StyledSwitch;
