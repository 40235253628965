'use client';

import { mdiShare } from '@mdi/js';
import IconButton from '../Button/IconButton';

const ShareButton = () => {
	const canShare = !!navigator.share;

	const share = async () => {
		if (canShare && typeof window !== 'undefined') {
			await navigator.share({
				title: document.title,
				text: document.title,
				url: window.location.href,
			});
		}
	};

	if (!canShare) return null;

	return (
		<IconButton
			icon={mdiShare}
			title="Deze pagina delen"
			onClick={share}
			label="Delen"
		/>
	);
};

export default ShareButton;
