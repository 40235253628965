'use client';

import IconButton from '@/components/Button/IconButton';
import useLocalStorage from '@/hooks/useLocalStorage';
import { Popover, RadioGroup, Switch, Transition } from '@headlessui/react';
import {
	mdiCheckBold,
	mdiChevronDown,
	mdiEye,
	mdiRadioboxBlank,
	mdiRadioboxMarked,
} from '@mdi/js';
import Icon from '@mdi/react';
import StyledSwitch from '../Switch/StyledSwitch';
import { useIsSimulationEnabled } from './ArticleAccessibilityBackgroundImplementation';
import { DEFAULT_TEXT_SIZE, TEXT_SIZES } from './ArticleAccessibilityTextSize';

type ColorBlindnessSimulationFilter = {
	id: string | false;
	displayName: string;
};

export const COLOR_BLINDNESS_SIMULATION_FILTERS: Array<ColorBlindnessSimulationFilter> =
	[
		{
			id: false,
			displayName: 'Geen kleurenblindheid',
		},
		{
			id: 'tritanopia',
			displayName: 'Tritanopie',
		},
		{
			id: 'deuteranopia',
			displayName: 'Deuteranopie',
		},
		{
			id: 'protanopia',
			displayName: 'Protanopie',
		},
		{
			id: 'achromatopsia',
			displayName: 'Achromatopsie',
		},
	];

const ArticleAccessibilityMenu = () => {
	const [isCustomTextSizeEnabled, setIsCustomTextSizeEnabled] =
		useLocalStorage<string>('isCustomTextSizeEnabled', DEFAULT_TEXT_SIZE);

	const [isDyslexiaFontEnabled, setIsDyslexiaFontEnabled] =
		useLocalStorage<boolean>('isDyslexiaFontEnabled', false);

	const isSimulationEnabled = useIsSimulationEnabled();

	const [isVisualFieldLossTubeEnabled, setIsVisualFieldLossTubeEnabled] =
		useLocalStorage('isVisualFieldLossTubeEnabled', false);

	return (
		<>
			<Popover className="z-20">
				{({ open }) => (
					<>
						<IconButton
							isPopoverButton
							icon={mdiEye}
							label={
								<>
									Instellingen
									{isSimulationEnabled && (
										<div className="ml-1 inline-block translate-x-1 rounded-full bg-primary-700 px-2 text-sm text-white">
											Simulatie aan
										</div>
									)}
								</>
							}
							title="Leesvoorkeuren en simulaties"
							className={`${
								open
									? 'rounded-b-none rounded-t-2xl after:rounded-b-none'
									: ''
							} transition-all`}
						/>
						<Transition
							enter="transition duration-200 ease-out"
							enterFrom="motion-safe:scale-y-75 opacity-0"
							enterTo="motion-safe:scale-100 opacity-100"
							leave="transition duration-100 ease-out"
							leaveFrom="motion-safe:scale-100 opacity-100"
							leaveTo="motion-safe:scale-y-75 opacity-0"
							className="z-100 origin-top"
						>
							<Popover.Panel className="absolute z-100 max-md:-inset-x-2 md:-left-2">
								<div className="not-prose mx-auto flex w-full max-w-full flex-col items-stretch rounded-lg bg-slate-50 text-primary-950 shadow-2xl ring-2 ring-black/5 ring-opacity-5 md:divide-x lg:grid lg:max-w-[40rem] lg:grid-cols-2 dark:bg-slate-900 dark:text-slate-50 dark:ring-primary/20">
									<section
										aria-label="Voorkeuren"
										className="flex flex-col p-4"
									>
										<header className="font-brand text-lg font-bold">
											Voorkeuren
										</header>
										<div className="flex flex-col gap-2">
											<SettingsListbox
												label="Tekstgrootte"
												options={TEXT_SIZES}
												selectedOption={
													TEXT_SIZES.find(
														(size) =>
															size.id ===
															isCustomTextSizeEnabled,
													) ??
													TEXT_SIZES[
														Math.floor(
															TEXT_SIZES.length /
																2,
														)
													]
												}
												setSelectedOption={(option) => {
													setIsCustomTextSizeEnabled(
														typeof option.id ===
															'string'
															? option.id
															: DEFAULT_TEXT_SIZE,
													);
												}}
											/>
											<SettingSwitch
												label="Dyslexie lettertype"
												checked={isDyslexiaFontEnabled}
												onChange={() => {
													setIsDyslexiaFontEnabled(
														!isDyslexiaFontEnabled,
													);
												}}
											/>
										</div>
									</section>
									<hr className="md:hidden" />
									<section
										aria-label="Simulaties"
										className="flex flex-col p-4"
									>
										<header className="font-brand text-lg font-bold">
											Simulaties
										</header>
										<div className="flex flex-col gap-2">
											<ColorBlindnessSimulationFilterDropdown />
											<BlurredVisionSimulationFilterSwitch />
											<SettingSwitch
												label="Gezichtsveld (koker)"
												checked={
													isVisualFieldLossTubeEnabled
												}
												onChange={() => {
													setIsVisualFieldLossTubeEnabled(
														!isVisualFieldLossTubeEnabled,
													);
												}}
											/>
										</div>
									</section>
								</div>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		</>
	);
};

export const ColorBlindnessSimulationFilterDropdown = () => {
	const [isColorBlindVisionEnabled, setIsColorBlindVisionEnabled] =
		useLocalStorage<boolean | string>('isColorBlindVisionEnabled', false);

	return (
		<SettingsListbox
			label="Kleurenblindheid"
			options={COLOR_BLINDNESS_SIMULATION_FILTERS}
			selectedOption={
				COLOR_BLINDNESS_SIMULATION_FILTERS.find(
					(filter) => filter.id === isColorBlindVisionEnabled,
				) ?? COLOR_BLINDNESS_SIMULATION_FILTERS[0]
			}
			setSelectedOption={(option) => {
				setIsColorBlindVisionEnabled(option.id);
			}}
		/>
	);
};

export const BlurredVisionSimulationFilterSwitch = () => {
	const [isBlurredVisionEnabled, setIsBlurredVisionEnabled] =
		useLocalStorage<boolean>('isBlurredVisionEnabled', false);

	return (
		<SettingSwitch
			label="Vervaagd zicht"
			checked={isBlurredVisionEnabled}
			onChange={() => {
				setIsBlurredVisionEnabled(!isBlurredVisionEnabled);
			}}
		/>
	);
};

const SettingSwitch = ({
	label,
	checked,
	onChange,
}: {
	label: string | JSX.Element;
	checked: boolean;
	onChange: (checked: boolean) => void;
}) => {
	return (
		<Switch.Group>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
			<div
				className="group flex w-full cursor-pointer items-center justify-between rounded-md border-2 p-4 transition-all focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 motion-safe:active:scale-95"
				onClick={() => onChange(!checked)}
			>
				<Switch.Label className="mr-4 origin-left cursor-pointer text-base font-semibold">
					{label}
				</Switch.Label>
				<StyledSwitch checked={checked} onChange={onChange} />
			</div>
		</Switch.Group>
	);
};

type SettingsListboxOption = {
	id: string | boolean;
	displayName: string;
};

const SettingsListbox = ({
	label,
	options,
	selectedOption,
	setSelectedOption,
}: {
	label: string;
	options: Array<SettingsListboxOption>;
	selectedOption: SettingsListboxOption;
	setSelectedOption: (option: SettingsListboxOption) => void;
}) => {
	return (
		<Popover>
			{({ open, close }) => (
				<>
					<Popover.Button className="flex w-full items-center justify-between rounded-md border-2 p-4 text-left text-base font-semibold transition-all motion-safe:active:scale-95">
						<span>
							<span className="sr-only">{label}: </span>
							{selectedOption.displayName}
						</span>
						<Icon
							path={mdiChevronDown}
							size={1}
							className={`${
								open ? '-scale-y-100' : 'scale-y-100'
							} transition-all`}
						/>
					</Popover.Button>
					<Transition
						enter="transition duration-200 ease-out"
						enterFrom="motion-safe:scale-y-75 opacity-0"
						enterTo="motion-safe:scale-100 opacity-100"
						leave="transition duration-100 ease-out"
						leaveFrom="motion-safe:scale-100 opacity-100"
						leaveTo="motion-safe:scale-y-75 opacity-0"
						className="relative z-100 origin-top"
					>
						<Popover.Panel className="not-prose absolute inset-x-0 -top-[3.75rem] z-100 flex flex-col rounded-md bg-white text-primary-950 shadow-2xl ring-2 ring-black/5 ring-opacity-5 dark:bg-slate-950 dark:text-slate-50 dark:ring-primary/20">
							<header className="p-4 font-brand text-lg font-bold">
								{label}
							</header>
							<RadioGroup
								value={selectedOption}
								onChange={(option) => {
									setSelectedOption(option);
								}}
								className="flex flex-col divide-y"
							>
								{options.map((option) => (
									<RadioGroup.Option
										key={option.displayName}
										value={option}
										className="flex cursor-pointer gap-4 rounded-md px-4 py-3 text-base font-semibold transition-all hover:bg-black/10 motion-safe:active:scale-95 dark:hover:bg-white/5"
										// onMouseUpCapture={() => {
										// 	close();
										// }}
										onKeyDown={(event) => {
											if (event.key === 'Enter') {
												close();
											}
										}}
									>
										<Icon
											className="shrink-0"
											path={
												option.id === selectedOption.id
													? mdiRadioboxMarked
													: mdiRadioboxBlank
											}
											size={1}
										/>
										{option.displayName}
										{option.id === selectedOption.id && (
											<span className="sr-only">
												(Geselecteerd)
											</span>
										)}
									</RadioGroup.Option>
								))}
							</RadioGroup>
							<IconButton
								icon={mdiCheckBold}
								label="Sluiten"
								title="Sluiten"
								className="mb-3 mt-1 self-center"
								onClick={close}
							/>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
};

export default ArticleAccessibilityMenu;
