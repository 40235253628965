'use client';

import useLocalStorage from '@/hooks/useLocalStorage';
import localFont from 'next/font/local';
import { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

const openDyslexic = localFont({
	src: [
		{
			path: './fonts/OpenDyslexic-Regular.woff2',
			weight: '400',
			style: 'normal',
		},
		{
			path: './fonts/OpenDyslexic-Bold.woff2',
			weight: '700',
			style: 'normal',
		},
		{
			path: './fonts/OpenDyslexic-Italic.woff2',
			weight: '400',
			style: 'italic',
		},
	],
});

type TextSize = {
	id: 'xs' | 'sm' | 'md';
	displayName: string;
	className: string;
};

export const TEXT_SIZES: Array<TextSize> = [
	{
		id: 'xs',
		displayName: 'Kleinste tekst',
		className: 'prose-sm md:prose-md lg:prose-lg max-w-none',
	},
	{
		id: 'sm',
		displayName: 'Kleinere tekst',
		className: 'prose-md md:prose-lg lg:prose-xl max-w-none',
	},
	{
		id: 'md',
		displayName: 'Grote tekst',
		className: 'prose-lg md:prose-xl lg:prose-2xl',
	},
] as const;

export const DEFAULT_TEXT_SIZE = 'md';
export const DEFAULT_TEXT_CLASSNAMES =
	TEXT_SIZES.find((textSize) => textSize.id === DEFAULT_TEXT_SIZE)
		?.className ?? '';

interface ProseProps<T extends React.ElementType> {
	as?: T;
	maxTextSize?: TextSize['id'];
	children?: React.ReactNode;
}

export function Prose<T extends React.ElementType = 'div'>({
	as,
	maxTextSize,
	className,
	...props
}: ProseProps<T> &
	Omit<React.ComponentPropsWithoutRef<T>, keyof ProseProps<T>>) {
	const Component = as || 'div';
	const selfRef = useRef<HTMLDivElement>(null);

	// Text size
	const [isCustomTextSizeEnabled] = useLocalStorage<string>(
		'isCustomTextSizeEnabled',
		DEFAULT_TEXT_SIZE,
	);

	// Dyslexia font
	const [isDyslexiaFontEnabled] = useLocalStorage<boolean>(
		'isDyslexiaFontEnabled',
		false,
	);

	useEffect(() => {
		let setTextSize = isCustomTextSizeEnabled;

		// Cap at max text size if needed
		if (maxTextSize) {
			const textSizesOrder = TEXT_SIZES.map((textSize) => textSize.id);
			const maxTextSizeIndex = textSizesOrder.indexOf(maxTextSize);
			const preferredTextSizeIndex = textSizesOrder.indexOf(
				(isCustomTextSizeEnabled as TextSize['id']) ??
					DEFAULT_TEXT_SIZE,
			);
			if (preferredTextSizeIndex > maxTextSizeIndex) {
				setTextSize = maxTextSize;
			}
		}

		TEXT_SIZES.forEach((textSize) => {
			const classNames = textSize.className.split(' ');
			classNames.forEach((className) => {
				if (!selfRef.current) return;
				selfRef.current.classList.remove(className);
			});
		});
		const newClassNames = (
			TEXT_SIZES.find((textSize) => textSize.id === setTextSize)
				?.className ?? ''
		).split(' ');
		newClassNames.forEach((className) => {
			if (!selfRef.current) return;
			selfRef.current.classList.add(className);
		});
	}, [isCustomTextSizeEnabled, maxTextSize, selfRef.current]);

	useEffect(() => {
		if (!selfRef.current) return;

		if (isDyslexiaFontEnabled) {
			selfRef.current.classList.add(openDyslexic.className);
		} else {
			selfRef.current.classList.remove(openDyslexic.className);
		}
	}, [isDyslexiaFontEnabled, selfRef.current]);

	return (
		<Component
			ref={selfRef}
			className={twMerge(
				`prose relative ${DEFAULT_TEXT_CLASSNAMES} mx-auto max-w-3xl dark:prose-invert prose-h1:mb-0 prose-h3:text-primary-800 dark:prose-h3:text-primary-200`,
				className,
			)}
			{...props}
		/>
	);
}
