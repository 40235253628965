'use client';

import useLocalStorage from '@/hooks/useLocalStorage';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { COLOR_BLINDNESS_SIMULATION_FILTERS } from './ArticleAccessibilityMenu';

export const useIsSimulationEnabled = () => {
	const [isBlurredVisionEnabled] = useLocalStorage(
		'isBlurredVisionEnabled',
		false,
	);

	const [isColorBlindVisionEnabled] = useLocalStorage(
		'isColorBlindVisionEnabled',
		false,
	);

	const [isVisualFieldLossTubeEnabled] = useLocalStorage(
		'isVisualFieldLossTubeEnabled',
		false,
	);

	return (
		isBlurredVisionEnabled ||
		isColorBlindVisionEnabled ||
		isVisualFieldLossTubeEnabled
	);
};

export const ArticleAccessibilityVisualBackgroundImplementation = () => {
	const [hasRendered, setHasRendered] = useState(false);
	const [mousePosition, setMousePosition] = useState<null | {
		x: number;
		y: number;
	}>(null);

	useEffect(() => {
		setHasRendered(true);
	}, []);

	// Blurred vision and color blindness
	const [isBlurredVisionEnabled] = useLocalStorage(
		'isBlurredVisionEnabled',
		false,
	);

	const [isColorBlindVisionEnabled] = useLocalStorage(
		'isColorBlindVisionEnabled',
		false,
	);

	useEffect(() => {
		if (isBlurredVisionEnabled) {
			document
				.querySelector('html')
				?.classList.add('simulation-blurred-vision');
		} else {
			document
				.querySelector('html')
				?.classList.remove('simulation-blurred-vision');
		}
	}, [isBlurredVisionEnabled]);

	useEffect(() => {
		COLOR_BLINDNESS_SIMULATION_FILTERS.forEach((filter) => {
			document.body.classList.remove(
				`simulation-colorblindness-${filter.id}`,
			);
		});
		if (typeof isColorBlindVisionEnabled === 'string') {
			document.body.classList.add(
				`simulation-colorblindness-${isColorBlindVisionEnabled}`,
			);
		}
	}, [isColorBlindVisionEnabled]);

	const visualFieldLossTubeRef = useRef<HTMLDivElement>(null);

	const [isVisualFieldLossTubeEnabled] = useLocalStorage(
		'isVisualFieldLossTubeEnabled',
		false,
	);

	useEffect(() => {
		const onMouseMove = (event: MouseEvent) => {
			setMousePosition({
				x: event.clientX,
				y: event.clientY,
			});
		};
		document.addEventListener('mousemove', onMouseMove);
		return () => {
			document.removeEventListener('mousemove', onMouseMove);
		};
	}, [isVisualFieldLossTubeEnabled]);

	useEffect(() => {
		// Move the tube with the cursor
		if (visualFieldLossTubeRef.current && mousePosition) {
			visualFieldLossTubeRef.current.style.opacity = '1';
			visualFieldLossTubeRef.current.style.left = `${mousePosition.x}px`;
			visualFieldLossTubeRef.current.style.top = `${mousePosition.y}px`;
		}
	}, [mousePosition, visualFieldLossTubeRef.current]);

	if (!hasRendered) {
		return null;
	}

	return (
		<>
			{isVisualFieldLossTubeEnabled && (
				<motion.div
					ref={visualFieldLossTubeRef}
					className="pointer-events-none fixed left-1/2 top-1/2 z-200 size-48 -translate-x-1/2 -translate-y-1/2 rounded-full opacity-0 blur-lg transition-opacity duration-500"
					style={{
						boxShadow: '0 0 0 9999px #000',
					}}
				/>
			)}
		</>
	);
};
