'use client';

import { Popover } from '@headlessui/react';
import Icon from '@mdi/react';
import { FocusEventHandler, MouseEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';

const colorVariants: Record<string, string> = {
	flat: 'bg-slate-100 dark:bg-slate-900 text-slate-950 dark:text-slate-50 hover:bg-slate-200 dark:hover:bg-slate-800',
	primary:
		'bg-primary-100 dark:bg-primary-900 text-primary-950 dark:text-primary-50 hover:bg-primary-200 dark:hover:bg-primary-800',
	secondary:
		'bg-secondary-100 dark:bg-secondary-900 text-secondary-950 dark:text-secondary-50 hover:bg-secondary-200 dark:hover:bg-secondary-800',
	tertiary:
		'bg-tertiary-100 dark:bg-tertiary-900 text-tertiary-950 dark:text-tertiary-50 hover:bg-tertiary-200 dark:hover:bg-tertiary-800',
	quaternary:
		'bg-quaternary-100 dark:bg-quaternary-900 text-quaternary-950 dark:text-quaternary-50 hover:bg-quaternary-200 dark:hover:bg-quaternary-800',
	quinary:
		'bg-quinary-100 dark:bg-quinary-900 text-quinary-950 dark:text-quinary-50 hover:bg-quinary-200 dark:hover:bg-quinary-800',
} as const;

type ColorVariant = keyof typeof colorVariants;

const getIconButtonClassName = (
	colorVariant: ColorVariant,
	isLarge: boolean,
	hasLabel: boolean,
) =>
	`shadow-border-bottom-sm flex gap-2 items-center ${
		hasLabel ? 'px-4 py-2' : 'px-2 py-1.5'
	} ${colorVariants[colorVariant]} ${
		isLarge ? 'text-xl md:text-2xl' : 'text-lg'
	} font-bold rounded-3xl after:rounded-3xl transition-all`;

const IconButton = ({
	label,
	icon,
	title,
	onClick,
	onFocus,
	colorVariant = 'flat',
	className = '',
	isLarge = false,
	isPopoverButton = false,
	isDecorationOnly = false,
	isTogglePressed = undefined,
	type,
}: {
	label?: string | JSX.Element;
	icon?: string;
	title: string;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	onFocus?: FocusEventHandler<HTMLButtonElement>;
	colorVariant?: ColorVariant;
	className?: string;
	isLarge?: boolean;
	isPopoverButton?: boolean;
	isDecorationOnly?: boolean;
	isTogglePressed?: boolean;
	type?: 'button' | 'submit' | 'reset';
}) => {
	if (isPopoverButton) {
		return (
			<Popover.Button
				className={twMerge(
					getIconButtonClassName(colorVariant, isLarge, !!label),
					className,
				)}
				title={title}
				aria-pressed={isTogglePressed}
				onFocus={onFocus}
			>
				{!!icon && (
					<Icon
						className={label ? '-ms-1' : ''}
						path={icon}
						size={1}
					/>
				)}
				<span>{label}</span>
			</Popover.Button>
		);
	} else if (isDecorationOnly) {
		return (
			<div
				className={twMerge(
					getIconButtonClassName(colorVariant, isLarge, !!label),
					className,
				)}
			>
				{!!icon && (
					<Icon
						className={label ? '-ms-1' : ''}
						path={icon}
						size={1}
					/>
				)}
				{!!label && <span>{label}</span>}
			</div>
		);
	}

	return (
		<button
			className={twMerge(
				getIconButtonClassName(colorVariant, isLarge, !!label),
				className,
			)}
			onClick={onClick}
			onFocus={onFocus}
			title={title}
			aria-pressed={isTogglePressed}
			type={type}
		>
			{!!icon && (
				<Icon className={label ? '-ms-1' : ''} path={icon} size={1} />
			)}
			{!!label && <span>{label}</span>}
		</button>
	);
};

export default IconButton;
